import React, {FC, useContext, useEffect, useState} from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { Checkbox, CheckboxField, Fullname, Page } from '../../Components';
import Icons from '../../Icons';
import { getCollectedFormFields, getReport, getSample, shareResult } from '../../requests';
import { StateContext } from '../../state';

import './style.css';

const ShareDetailView: FC = () => {
    const {dispatch} = useContext(StateContext);
    const [options, setOptions] = useState<any>({});
    const [loading, setLoading] = useState<boolean>(false);
    const {sample_id} = useParams<any>();
    const [fullname, setFullname] = useState<string>('');
    const [shared, setShared] = useState<boolean>(false);
    const [hadConversation, setHadConversation] = useState<boolean | null>(null);
    const [useful, setUseful] = useState<string>('');
    const [plan, setPlan] = useState<any[]>([]);
    const [change_plan, setChangePlan] = useState<any[]>([]);
    const [useAgain, setUseAgain] = useState<string>('');
    const [notes, setNotes] = useState<string>('');
    const [isServiceUser, setIsServiceUser] = useState<boolean>(false);
    const history = useHistory();

    useEffect(() => {
        setLoading(true);
        getSample(sample_id).then(res => {
            if (res.data.count !== 1) {
                return history.goBack();
            }
            const sample = res.data.results[0];
            setIsServiceUser(sample.collected_from === "USER");
            getCollectedFormFields().then(res => {
                const opt = {
                    ...res.data, 
                    use_again: [
                        {id: 1, text: "Yes"},
                        {id: 2, text: "No"},
                        {id: 3, text: "Don't know"}
                    ],
                    had_conversation: [
                        {id: 1, text: "Yes"},
                        {id: 2, text: "No"}
                    ],change_plan: [
                        {id: 1, text: "Yes, I plan to...", value:"YES"},
                        {id: 2, text: "No", value:"NO"},
                        {id: 3, text: "Don’t have any of this drug left", value:"DON'T HAVE ANY OF THIS DRUG LEFT"},


                    ],
                }
                setOptions(opt);
                setLoading(false);
            })      
        })
        getReport(sample_id).then(res => {
            if (res.data.shared_at) {
                dispatch({type: "showAlert", payload: {
                    error: true,
                    message: `Resharing ${sample_id} is not allowed`,
                }});
                history.replace("/")
            }
        }).catch(() => {})
    }, [history, sample_id, dispatch]);

    const submitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            setLoading(true);
            let use_again = useAgain ? useAgain.toUpperCase() : null;
            if (use_again === "DON'T KNOW") {
                use_again = "DONT_KNOW";
            }

            const form = {
                fullname,
                shared,
                had_conversation: hadConversation,
                how_useful: useful,
                plan: plan.map(p => p.text),
                change_plan:change_plan.at(0)?.value,
                use_again,
                feedback: notes,
            };
            await shareResult(sample_id, form);
            dispatch({type: "showAlert", payload: {
                message: `${sample_id} Sample Status Updated`,
                status: 'Status: Check Complete',
            }});
            history.replace(`/samples/${sample_id}/download`)
        } catch (err: any) {
            dispatch({type: "showAlert", payload: {
                error: true,
                message: err.message,
            }});
        } finally {
            setLoading(false);
        }
    }

    let onCancel = undefined;
    if (!loading) {
        onCancel = () => history.goBack();
    }

    const IsDisabled: boolean = loading || !fullname || !shared || (isServiceUser && hadConversation === null)

    return (
        <Page full title="Sharing Results Survey" sample_id={sample_id} onCancel={onCancel} >
            <form className="sharingresults" onSubmit={submitHandler}> 
                <div className="warning">
                    <Icons.Alert />
                    Complete the sharing results survey to download this sample's analysis results.
                </div>
                <div className="field">
                    <label className="label">
                        Full name of staff that shared these results: <span className="required">*</span>
                    </label>
                    <Fullname disabled={loading} onChange={(fullname: string) => setFullname(fullname)} />
                </div>
                <div className="field">
                    <Checkbox
                        disabled={loading}
                        checked={shared}
                        onChange={(v: boolean) => setShared(v)}
                        label="Results were shared" 
                        required
                    />
                    {isServiceUser &&
                        <div className="field" style={{marginTop: '26px'}}>
                            <label className="label">A conversation was had with the service user: <span className="required">*</span></label>
                            {options.had_conversation && options.had_conversation.map((h: any, index: number) => {
                                return <Checkbox
                                            disabled={loading}
                                            key={index}
                                            checked={(hadConversation === true && h.text === 'Yes') || (hadConversation === false && h.text === 'No')}
                                            onChange={(v: boolean) => setHadConversation(v && h.text === 'Yes')}
                                            label={h.text}
                                        />
                            })}
                        </div>
                    }
                </div>
                <hr className="full full1" style={{width: '100%'}} />
                {isServiceUser &&
                    <>
                        <div className="field">
                            <label className="label">How useful are these drug checking results?</label>
                            {options.HowUsefulResults && options.HowUsefulResults.map((h: any, index: number) => {
                                return <Checkbox
                                            key={index}
                                            checked={useful === h.text}
                                            onChange={(v: boolean) => {
                                                if (v) {
                                                    setUseful(h.text);
                                                } else {
                                                    setUseful('');
                                                }
                                            }}
                                            label={h.text}
                                        />
                            })}
                        </div>
                        <CheckboxField 
                            form={change_plan}
                            question="Does knowing what's in your drugs change how you plan to use them? "
                            name="change_plan"
                            updateItems={(_name: string, selectedItem: any, action: 'push' | 'remove') => {
                                
                                if (action === 'remove') {
                                    setChangePlan([])
                                } else if (action === "push") {
                                        setChangePlan([selectedItem]);
                                   
                                }
                            }}
                            options={options.change_plan}
                        />
                        {change_plan.at(0)?.text==="Yes, I plan to..." && <CheckboxField
                            form={plan}
                            question="What do you plan to do with your drug? Check all that apply."
                            name="plan"
                            updateItems={(_name: string, selectedItem: any, action: 'push' | 'remove') => {
                                const index = plan.findIndex((item: any) => item.id === selectedItem.id);
                                if (index > -1 && action === 'remove') {
                                    setPlan(old => {
                                        return old.filter((_v: any, idx: number) => idx !== index);
                                    })
                                } else if (action === "push") {
                                    if (index === 0) {
                                        setPlan(old => {
                                            old[index] = selectedItem;
                                            return [...old];
                                        });
                                    } else if (index === -1) {
                                        setPlan(old => [...old, selectedItem])
                                    }
                                }
                            }}
                            options={options.DrugPlan}
                        />}
                        <div className="field">
                            <label className="label">Do you plan to use this drug checking service again?</label>
                            {options.use_again && options.use_again.map((h: any, index: number) => {
                                return <Checkbox
                                            key={index}
                                            checked={useAgain === h.text}
                                            onChange={(v: boolean) => {
                                                if (v) {
                                                    setUseAgain(h.text);
                                                } else {
                                                    setUseAgain('');
                                                }
                                            }}
                                            label={h.text}
                                        />
                            })}
                        </div>
                    </>
                }
                <div className="field">
                    <label className="label">
                        Do you have any feedback you’d like to share?
                    </label>
                    <textarea
                        value={notes}
                        onChange={e => setNotes(e.target.value)}
                        disabled={loading}
                        style={{width: '27.875rem'}}
                    />
                </div>
                <div className="field">
                    <button disabled={IsDisabled} className="btn btn1">Submit & Download</button>
                </div>
            </form>
        </Page>
    );
}

export default ShareDetailView;
